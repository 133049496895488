<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="班别名称:">
          <el-input
						v-model.trim="searchForm.fudaoclasstypename"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="班别类型:">
          <el-input
						v-model.trim="searchForm.fudaotypeval"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input
						v-model.trim="searchForm.schoolorgname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="getDataList"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>
    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
        label="班别名称"
        prop="fudaoclasstypename"
        align="center"
				width="300px"
      >
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.fudaoclasstypename
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="班别类型" prop="fudaotypeval" align="center" />
      <el-table-column label="项目类型" prop="projecttypeval" align="center" />
      <el-table-column label="教材费" prop="bookfee" align="center" />
      <el-table-column label="培训费" prop="fudaofee" align="center" />
      <el-table-column label="所属教学点" prop="schoolorgname" align="center" />
      <el-table-column label="审核状态" prop="approveflag" align="center">
        <template slot-scope="scope">
          {{ approveflag[scope.row.approveflag] }}
        </template>
      </el-table-column>
			<el-table-column label="操作" prop="" align="center">
				<template slot-scope="scope">
				  <el-button @click="handleClickDetails(scope.row)" type="text" size="small">详情</el-button>
				</template>
			</el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 90%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="40%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="辅导班班别:">
          {{ dynamicValidateForm.fudaoclasstypename }}
        </el-form-item>
        <el-form-item label="班别类型:">
          {{ dynamicValidateForm.fudaotypeval }}
        </el-form-item>
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttypeval }}
        </el-form-item>
        <el-form-item label="辅导费:">
          {{ dynamicValidateForm.fudaofee }}
        </el-form-item>
        <el-form-item label="教材费:">
          {{ dynamicValidateForm.bookfee }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="是否审核:">
          {{ approveflag[dynamicValidateForm.approveflag] }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="89%"
      :visible.sync="showAddModal"
      v-if="showAddModal"
			:close-on-click-modal='false'
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="125px"
        class="demo-dynamic form-item-w-18"
        status-icon
        :rules="rules"
				@submit.native.prevent
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="projecttypeval" label="项目:">
						<el-input
							v-model="dynamicValidateForm.projecttypeval"
							disabled
						></el-input>
        </el-form-item>
        <el-form-item label="班别名称:" prop="fudaoclasstypename">
          <el-input
            v-model="dynamicValidateForm.fudaoclasstypename"
            disabled
            placeholder="请选择"
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                fudaoclasstype_dialogresult,
                'showClassModal',
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="班别类型:" prop="fudaotypeval">
          <el-input
            disabled
            v-model="dynamicValidateForm.fudaotypeval"
          ></el-input>
        </el-form-item>
        <el-form-item label="培训费:" prop="fudaofee">
          <el-input
						v-model.trim="dynamicValidateForm.fudaofee"
						clearable></el-input>
        </el-form-item>
        <el-form-item label="教材费:" prop="bookfee">
          <el-input v-model.trim="dynamicValidateForm.bookfee" clearable></el-input>
        </el-form-item>
				<div v-show="isShsow">
				<p style="margin:0 0 10px 0;">费用详情：</p>
					<ol>
					<li
					  v-for="(item, i) in dynamicValidateForm.moreItems1"
					  :key="i"
						style="background: aliceblue;"
					>
					<div style="display: flex;flex-wrap: wrap;" class="flex-free">
						<span style="line-height: 43px;">{{i+1}}.</span>
				    <el-form-item
				      label="学生类型："
				      :prop="'moreItems1.' + i + '.type'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				    <el-input v-model.trim="item.type" clearable></el-input>
				    </el-form-item>
				    <el-form-item
				      label="总部管理费."
				      :prop="'moreItems1.' + i + '.zbmanagementfee'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.zbmanagementfee" clearable/>
				    </el-form-item>
				    <el-form-item
				      label="德立招生员提成."
				      :prop="'moreItems1.' + i + '.zsycommission'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.zsycommission" clearable/>%
				    </el-form-item>
				    <el-form-item
				      label="教务提成："
				      :prop="'moreItems1.' + i + '.jwcommission'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.jwcommission" clearable></el-input>
				    </el-form-item>
				    <el-form-item
				      label="财务提成 ："
				      :prop="'moreItems1.' + i + '.cwcommission'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.cwcommission" clearable></el-input>
				    </el-form-item>
				    <el-form-item
				      label="校外招生员提成:"
				      :prop="'moreItems1.' + i + '.xwzsycommission'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.xwzsycommission" clearable></el-input>%
				    </el-form-item>
				    <el-form-item
				      label="报考费："
				      :prop="'moreItems1.' + i + '.registrationfee'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.registrationfee" clearable></el-input>
				    </el-form-item>

				    <el-form-item
				      label="家和员工提成："
				      :prop="'moreItems1.' + i + '.jhcommission'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.jhcommission" clearable></el-input>%
				    </el-form-item>
				    <el-form-item
				      label="德立分成："
				      :prop="'moreItems1.' + i + '.dlproportion'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.dlproportion" clearable></el-input>%
				    </el-form-item>

				    <el-form-item
				      label="家和分成："
				    	:prop="'moreItems1.' + i + '.jhproportion'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.jhproportion" clearable></el-input>%
				    </el-form-item>
				    <el-form-item
				      label="网课费："
				      :prop="'moreItems1.' + i + '.coursefee'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.coursefee" clearable></el-input>
				    </el-form-item>
				    <el-form-item
				      label="面授师资费："
				      :prop="'moreItems1.' + i + '.teachersfee'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.teachersfee" clearable></el-input>
				    </el-form-item>
				    <el-form-item
				      label="题库费："
				      :prop="'moreItems1.' + i + '.questionfee'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				      <el-input v-model.trim="item.questionfee" clearable></el-input>
				    </el-form-item>
				    <el-form-item
				      label="课程机构："
				      :prop="'moreItems1.' + i + '.techtype'"
							class="demo-dynamic form-item-w-13"
				      :rules="{
				        required: true,
				        message: '必填字段',
				        trigger: 'blur',
				      }"
				    >
				    	<el-select v-model="item.techtype" clearable>
				    		<el-option value="1" label="德立"></el-option>
				    		<el-option value="2" label="家和"></el-option>
				    	</el-select>
				    </el-form-item>
				    <el-button
				      v-if="i !== 0"
				      @click="onRemoveForm(i)"
				      style="margin: 0 10px; height: 40px"
				      >删除</el-button
				    >
						<el-button
						  v-if="i == 0"
						  style="margin: 0 10px; height: 40px; visibility: hidden;"
						  >删除</el-button
						>
				  </div>
					</li>
					</ol>
				  <el-form-item >
				    <el-button @click="addMoreItem">添加一行</el-button>
				  </el-form-item>
					</div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 审批 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      title="审批："
			v-dialogDrag
      :visible.sync="showApprovalModal"
			v-if="showApprovalModal"
      append-to-body
      width="25%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        label-width="35%"
        :rules="rules"
      >
        <el-form-item label="审核是否通过：" prop="passflag">
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 0, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批意见：" prop="approvedesc">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="headApproval('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择班别 -->
    <el-dialog
      class="btn-2b5a95"
      width="60%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择班别："
      :visible.sync="showClassModal"
			v-if="showClassModal"
      append-to-body

    >
      <el-button type="primary" @click="onConfirmClass">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="班别名称:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="班别类型:" prop="fudaotypeval">
              <el-input
									v-model.trim="selectSearchForm.fudaotypeval"
									@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
									clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>


      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="班别类型" prop="fudaotypeval" align="center" />
        <el-table-column label="班别编号" prop="code" align="center" />
        <el-table-column label="班别名称" prop="name" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

		<!-- 主列表详情-->
		<el-dialog
		  class="btn-2b5a95"
		  width="80%"
		  title="详情："
			v-dialogDrag
		  :visible.sync="showshowDetailModal"
			:close-on-click-modal='false'
		  v-if="showshowDetailModal"
			@close="onCloseSelect"
		  append-to-body
		>
		  <!-- 操作按钮 -->
		  <div class="select-btn" style="padding-bottom: 20px;">
		    <el-button type="primary" @click="btnAddRJ">增加</el-button>
				<el-button type="primary" @click="btnEditRJ"
				  >修改</el-button
				>
		    <el-button type="primary" @click="btnDelRJ">删除</el-button>
		    <el-button type="primary" @click="btnReloadRJ">刷新</el-button>
		  </div>
		  <el-table
		    :data="showTable.list"
		    style="width: 100%"
		    border
				:key="key"
		    stripe
		    :header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="80" align="center" />
		    <el-table-column label="流水号" prop="id" align="center" width="200" />
		    <el-table-column label="学生类型" prop="type" align="center">
				<template slot-scope="scope">
						{{scope.row.type}}
					</template>
				</el-table-column>
		    <el-table-column label="总部管理费" prop="zbmanagementfee" align="center" />
		    <el-table-column
		      label="德立招生员提成"
		      prop="zsycommission"
		      align="center"
		    >
					<template slot-scope="scope">
						{{scope.row.zsycommission}}%
					</template>
				</el-table-column>
				<el-table-column label="教务提成" prop="jwcommission" align="center" />
				<el-table-column label="财务提成" prop="cwcommission" align="center" />
		    <el-table-column label="校外（渠道）招生员提成" prop="xwzsycommission" align="center" >
					<template slot-scope="scope">
							{{scope.row.xwzsycommission}}%
						</template>
					</el-table-column>
		    <el-table-column label="报考费" prop="registrationfee" align="center" />
		    <el-table-column label="家和员工提成" prop="jhcommission" align="center" >
					<template slot-scope="scope">
							{{scope.row.jhcommission}}%
						</template>
					</el-table-column>
		    <el-table-column label="德立分成" prop="dlproportion" align="center" >
					<template slot-scope="scope">
							{{scope.row.dlproportion}}%
						</template>
					</el-table-column>
		    <el-table-column label="家和分成" prop="jhproportion" align="center" >
					<template slot-scope="scope">
							{{scope.row.jhproportion}}%
						</template>
					</el-table-column>
				<el-table-column label="网课费" prop="coursefee" align="center" />
				<el-table-column label="面授师资费" prop="teachersfee" align="center" />
				<el-table-column label="题库费" prop="questionfee" align="center" />
				<el-table-column label="课程对接机构" prop="techtype" align="center" >
					<template slot-scope="scope">
							{{techtype[scope.row.techtype]}}
						</template>
				</el-table-column>
		  </el-table>
			<el-pagination
			  background
			  @current-change="handleShowChange"
			  layout="total, prev, pager, next"
			  :total="showTable.totalcount"
			></el-pagination>
		</el-dialog>
		<el-dialog
		  class="btn-2b5a95"
		  width="93%"
			v-dialogDrag
		  title="详情新增"
		  :visible.sync="showRJAddModal"
		  v-if="showRJAddModal"
			:close-on-click-modal='false'
		  append-to-body
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    label-width="125px"
		    class="demo-dynamic form-item-w-14"
		    status-icon
		    :rules="rules"
				@submit.native.prevent
		  >
				<ol>
					<li
					  v-for="(item, i) in dynamicValidateForm.moreItems"
					  :key="i"
						style="background: aliceblue;"
					>
					<div style="display: flex; flex-wrap: wrap;">
						<span style="line-height: 43px;">{{i+1}}.</span>
						<el-form-item
						  label="学生类型："
						  :prop="'moreItems.' + i + '.type'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						<el-input v-model="item.type" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="总部管理费."
						  :prop="'moreItems.' + i + '.zbmanagementfee'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.zbmanagementfee" clearable/>
						</el-form-item>
						<el-form-item
						  label="德立招生员提成."
						  :prop="'moreItems.' + i + '.zsycommission'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.zsycommission" clearable/>%
						</el-form-item>
						<el-form-item
						  label="教务提成："
						  :prop="'moreItems.' + i + '.jwcommission'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.jwcommission" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="财务提成 ："
						  :prop="'moreItems.' + i + '.cwcommission'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.cwcommission" clearable></el-input>
						</el-form-item>
					  <el-form-item
					    label="校外招生员提成:"
					    :prop="'moreItems.' + i + '.xwzsycommission'"
					    :rules="{
					      required: true,
					      message: '必填字段',
					      trigger: 'blur',
					    }"
					  >
					    <el-input v-model.trim="item.xwzsycommission" clearable></el-input>%
					  </el-form-item>
						<el-form-item
						  label="报考费："
						  :prop="'moreItems.' + i + '.registrationfee'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.registrationfee" clearable></el-input>
						</el-form-item>

						<el-form-item
						  label="家和员工提成："
						  :prop="'moreItems.' + i + '.jhcommission'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.jhcommission" clearable></el-input>%
						</el-form-item>
						<el-form-item
						  label="德立分成："
						  :prop="'moreItems.' + i + '.dlproportion'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.dlproportion" clearable></el-input>%
						</el-form-item>

						<el-form-item
						  label="家和分成："
							:prop="'moreItems.' + i + '.jhproportion'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.jhproportion" clearable></el-input>%
						</el-form-item>
						<el-form-item
						  label="网课费："
						  :prop="'moreItems.' + i + '.coursefee'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.coursefee" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="面授师资费："
						  :prop="'moreItems.' + i + '.teachersfee'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.teachersfee" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="题库费："
						  :prop="'moreItems.' + i + '.questionfee'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="item.questionfee" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="课程对接机构："
						  :prop="'moreItems.' + i + '.techtype'"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
							<el-select v-model="item.techtype" clearable>
								<el-option value="1" label="德立"></el-option>
								<el-option value="2" label="家和"></el-option>
							</el-select>
						</el-form-item>
					  <el-button
					    v-if="i !== 0"
					    @click="onRemoveMoreForm(i)"
					    style="margin: 0 10px; height: 40px"
					    >删除</el-button
					  >
						<el-button
						  v-if="i == 0"
						  style="margin: 0 10px; height: 40px;visibility: hidden;"
						  >删除</el-button
						>
						</div>
					</li>
				</ol>
		    <el-form-item>
		      <el-button @click="addFormItem">添加一行</el-button>
		    </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button
		      type="primary"
		      @click="onSubmitDetailEdit('dynamicValidateForm')"
		      :loading="!$store.state.global.isEndRequest"
		      >提交
		    </el-button>
		  </span>
		</el-dialog>

		<el-dialog
		  class="btn-2b5a95"
		  width="84%"
			v-dialogDrag
		  title="详情修改"
		  :visible.sync="inputetailModal"
		  v-if="inputetailModal"
			:close-on-click-modal='false'
		  append-to-body
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    label-width="138px"
		    class="demo-dynamic form-item-w-18"
		    status-icon
		    :rules="rules"
				@submit.native.prevent
		  >
						<el-form-item
						  label="学生类型："
						  prop="type"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						 <el-input v-model="dynamicValidateForm.type" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="总部管理费:"
						  prop="zbmanagementfee"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.zbmanagementfee" clearable/>
						</el-form-item>
						<el-form-item
						<el-form-item
						  label="德立招生员提成:"
						  prop="zbmanagementfee"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.zsycommission" clearable/>%
						</el-form-item>
						<el-form-item
						  label="教务提成："
						  prop="jwcommission"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.jwcommission" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="财务提成："
						  prop="cwcommission"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.cwcommission" clearable></el-input>
						</el-form-item>
					  <el-form-item
					    label="校外招生员提成："
					    prop="xwzsycommission"
							class="demo-dynamic form-item-w-13"
					    :rules="{
					      required: true,
					      message: '必填字段',
					      trigger: 'blur',
					    }"
					  >
					    <el-input v-model.trim="dynamicValidateForm.xwzsycommission" clearable></el-input>%
					  </el-form-item>
						<el-form-item
						  label="报考费："
						  prop="registrationfee"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.registrationfee" clearable></el-input>
						</el-form-item>

						<el-form-item
						  label="家和员工提成："
						  prop="jhcommission"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.jhcommission" clearable></el-input>%
						</el-form-item>
						<el-form-item
						  label="德立分成："
						  prop="dlproportion"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.dlproportion" clearable></el-input>%
						</el-form-item>

						<el-form-item
						  label="家和分成："
							prop="jhproportion"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.jhproportion" clearable></el-input>%
						</el-form-item>
						<el-form-item
						  label="网课费："
						  prop="coursefee"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.coursefee" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="面授师资费："
						  prop="teachersfee"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.teachersfee" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="题库费："
						  prop="questionfee"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
						  <el-input v-model.trim="dynamicValidateForm.questionfee" clearable></el-input>
						</el-form-item>
						<el-form-item
						  label="课程对接机构："
						  prop="techtype"
							class="demo-dynamic form-item-w-13"
						  :rules="{
						    required: true,
						    message: '必填字段',
						    trigger: 'blur',
						  }"
						>
							<el-select v-model="dynamicValidateForm.techtype" clearable>
								<el-option :value="1" label="德立"></el-option>
								<el-option :value="2" label="家和"></el-option>
							</el-select>
						</el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button
		      type="primary"
		      @click="submitInputFormEdit('dynamicValidateForm')"
		      :loading="!$store.state.global.isEndRequest"
		      >提交
		    </el-button>
		  </span>
		</el-dialog>
  </div>
</template>

<script>
import { _approveflag, _typeStudent, _techtypeflag} from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  stufudaofeestandard_list,
  stufudaofeestandard_input,
	stufodaofeestandarddetails_list,
	stufodaofeestandarddwtaills_delete,
	stufudaofeestandarddetail_input,
	stufudaofeestandarddetil_save2,
  stufudaofeestandard_save,
	stufudaofeestandard_save2,
  stufudaofeestandard_delete,
  techfeestandard_approvecheck,
  stufudaofeestandard_approvesave,
  stufudaofeestandard_requestapprove
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
  _fudaoclasstype_dialogresult,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "stufudaofeestandard",
  components: {},
  props: {
    fudaoclasstype_dialogresult: {
      default: () => _fudaoclasstype_dialogresult,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
			isShsow: false,
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
			inputetailModal: false,//修改详情
			showTable: {},
      showAddModal: false, // 添加
			type:_typeStudent,
			techtype: _techtypeflag,
			showRJAddModal: false, //详情新增框
      showshowDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        passflag: [{ trigger: "blur", message: "必填字段", required: true }],
        // approvedesc: [{ trigger: "blur", message: "必填字段", required: true }],
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        projecttypeval: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        fudaoclasstypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        fudaotypeval: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        fudaofee: [{ trigger: "blur", message: "必填字段", required: true }],
        bookfee: [{ trigger: "blur", message: "必填字段", required: true }],
				zbmanagementfee: [{ trigger: "blur", message: "必填字段", required: true }],
				zsycommission: [{ trigger: "blur", message: "必填字段", required: true }],
				jwcommission: [{ trigger: "blur", message: "必填字段", required: true }],
				cwcommission:  [{ trigger: "blur", message: "必填字段", required: true }],
				xwzsycommission: [{ trigger: "blur", message: "必填字段", required: true }],
				registrationfee: [{ trigger: "blur", message: "必填字段", required: true }],
				jhcommission: [{ trigger: "blur", message: "必填字段", required: true }],
				dlproportion:[{ trigger: "blur", message: "必填字段", required: true }],
				jhproportion: [{ trigger: "blur", message: "必填字段", required: true }],
				coursefee: [{ trigger: "blur", message: "必填字段", required: true }],
				teachersfee: [{ trigger: "blur", message: "必填字段", required: true }],
				questionfee: [{ trigger: "blur", message: "必填字段", required: true }],
				techtype: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      showApprovalModal: false, //审批框
      selectTable: {},
      selectTableUrl: "",
      showClassModal: false,
      selectSearchForm: {}, // 选择搜索
      dynamicValidateForm: {
        moreItems: [
          {
            zbmanagementfee: "",
            type: "",
            zsycommission: "",
            jwcommission: "",
            cwcommission: "",
            xwzsycommission: "",
            registrationfee: "",
            jhcommission: "",
            dlproportion: "",
            jhproportion: "",
            coursefee: "",
            teachersfee: "",
            questionfee: "",
            techtype: "",
						stufudaofeestandardid: ""
          },
        ],
      }, //表单
			dynamicValidateForm: {
			  moreItems1: [
			    {
			      zbmanagementfee: "",
			      type: "",
			      zsycommission: "",
			      jwcommission: "",
			      cwcommission: "",
			      xwzsycommission: "",
			      registrationfee: "",
			      jhcommission: "",
			      dlproportion: "",
			      jhproportion: "",
			      coursefee: "",
			      teachersfee: "",
			      questionfee: "",
			      techtype: "",
						stufudaofeestandardid: ""
			    },
			  ],
			}, //表单
      approveflag: _approveflag,
			showDetailModal: false,
			key: '',
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick","isExpand"]),
    ...mapActions("selectData", [
      "org_combox",
      "org_comboxchild",
      "dic_combox",
    ]),

    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		reset() {
			this.searchForm = {
				fudaoclasstypename: null,
				fudaotypeval: null,
				schoolorgname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				name: null,
				fudaotypeval: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null)
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: stufudaofeestandard_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: stufudaofeestandard_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.tstufudaofeestandard;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dynamicValidateForm = {
        projecttypekey: 2,
        projecttypeval: "技能培训",
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
				moreItems1: [
						{
						zbmanagementfee: "",
						type: "",
						zsycommission: "",
						jwcommission: "",
						cwcommission: "",
						xwzsycommission: "",
						registrationfee: "",
						jhcommission: "",
						dlproportion: "",
						jhproportion: "",
						coursefee: "",
						teachersfee: "",
						questionfee: "",
						techtype: "",
						stufudaofeestandardid: ""
					}]

				}
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
			this.dialogTitle = "添加：";
			this.isShsow = true;
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      selectCheck(this.multipleSelection, "修改", false, () => {
				this.dialogTitle = "修改";
				this.isShsow = false;
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
		// 详情
		handleClickDetails(row) {
			this.itemRow = row;
			this.showList();
		},
		showList() {
			myRequest({
			  url: stufodaofeestandarddetails_list,
			  data: {
			    stufudaofeestandardid: this.itemRow.id,
			  },
			}).then((res) => {
			  if (res.data.code === "200") {
					this.multipleSelection = [];
					this.showTable = res.data.data;
			    this.showshowDetailModal = true;
			  }
			});
		},
		//切换查看表格页
		handleShowChange(val) {
		  this.pageinfo.pageindex = val;
		  this.showList();
		},
		// 新增
		btnAddRJ(){
			this.dynamicValidateForm = {
				moreItems: [
					{
					zbmanagementfee: "",
					type: "",
					zsycommission: "",
					jwcommission: "",
					cwcommission: "",
					xwzsycommission: "",
					registrationfee: "",
					jhcommission: "",
					dlproportion: "",
					jhproportion: "",
					coursefee: "",
					teachersfee: "",
					questionfee: "",
					techtype: "",
					stufudaofeestandardid: ""
				}]

			}
			console.log(this.dynamicValidateForm , 'this.dynamicValidateForm ')
			this.dynamicValidateForm.stufudaofeestandardid = this.itemRow.id
			console.log(this.dynamicValidateForm.stufudaofeestandardid, this.itemRow.id,'this.itemRow.id')
			this.showRJAddModal = true;
		},
		// 新增一行
		addFormItem() {
			console.log('-----')
			this.dynamicValidateForm.moreItems.push({
				   zbmanagementfee: "",
				   type: "",
				   zsycommission: "",
				   jwcommission: "",
				   cwcommission: "",
				   xwzsycommission: "",
				   registrationfee: "",
				   jhcommission: "",
				   dlproportion: "",
				   jhproportion: "",
				   coursefee: "",
				   teachersfee: "",
				   questionfee: "",
				   techtype: "",
					 stufudaofeestandardid: ""
				});

		},
		addMoreItem() {
			this.dynamicValidateForm.moreItems1.push({
				   zbmanagementfee: "",
				   type: "",
				   zsycommission: "",
				   jwcommission: "",
				   cwcommission: "",
				   xwzsycommission: "",
				   registrationfee: "",
				   jhcommission: "",
				   dlproportion: "",
				   jhproportion: "",
				   coursefee: "",
				   teachersfee: "",
				   questionfee: "",
				   techtype: "",
					 stufudaofeestandardid: ""
				});
		},
		onRemoveMoreForm(index) {
		  this.dynamicValidateForm.moreItems.splice(index, 1);
		},
		onRemoveForm(index) {
			this.dynamicValidateForm.moreItems1.splice(index, 1);
		},
		btnEditRJ(){
			selectCheck(this.multipleSelection, "修改", false, () => {
			  this.inputDetail();
			});
		},
		inputDetail() {
		  myRequest({
		    url: stufudaofeestandarddetail_input,
		    data: {
		      id: this.multipleSelection[0].id,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.dynamicValidateForm = res.data.data.vstufudaofeestandarddetail;
					this.dynamicValidateForm.stufudaofeestandardid = this.itemRow.id;
		      this.inputetailModal = true;
		    }
		  });
		},
		//修改详情
		submitInputFormEdit(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
					myRequest(
					  {
							method: "post",
					    url: stufudaofeestandarddetil_save2,
					    data: this.$qs.stringify(this.dynamicValidateForm, {
					      arrayFormat: "repeat",
					    }),
					  },
					  {
					    that: this,
					    modal: "inputetailModal",

					    isRefTableList: true,
					  }
					);
		    }
		  });
		},
		//查看详情的新增提交 click
		onSubmitDetailEdit(formName) {
			console.log(formName, 'formName')
			const moreDataKey = ["moreItems"];
			const moreItemKey = [
			  "zbmanagementfee",
			  "type",
			  "zsycommission",
			  "jwcommission",
			  "cwcommission",
			  "xwzsycommission",
			  "registrationfee",
			  "jhcommission",
			  "dlproportion",
			  "jhproportion",
				"coursefee",
				"teachersfee",
				"questionfee",
				"techtype",
				"stufudaofeestandardid"
			];
			moreItemKey.map((i) => (this.dynamicValidateForm[i] = []));
			for (let moreKey in moreDataKey){
			  for(let j in moreItemKey){
			    for(let i in this.dynamicValidateForm[moreDataKey[moreKey]]){
			      for (let key in this.dynamicValidateForm[moreDataKey[moreKey]][i]){
			        if (this.dynamicValidateForm[moreDataKey[moreKey]][i][key] && key === moreItemKey[j]) {
			          //有值才push，否则是空字符串
			          this.dynamicValidateForm[key].push(this.dynamicValidateForm[moreDataKey[moreKey]][i][key]);
			        }
			      }
			    }
			  }
			}
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
					this.dynamicValidateForm.stufudaofeestandardid = this.itemRow.id
					console.log(this.dynamicValidateForm.stufudaofeestandardid, this.itemRow.id,'this.itemRow.id')
					myRequest(
					  {
							method: "post",
					    url: stufudaofeestandarddetil_save2,
					    data: this.$qs.stringify(this.dynamicValidateForm, {
		        arrayFormat: "repeat",
		      }),
					  },
					  {
					    that: this,
					    modal: "showRJAddModal",

					    isRefTableList: true,
					  }).then((res) => {
							console.log(res, 'res')
							if(res.data.code == "200") {
								this.showRJAddModal = false;
							}

						})

		    }
		  });

		},
		//
		//删除列表
		btnDelRJ(){
			  myRequest(
			    {
			      method: "post",
			      url: stufodaofeestandarddwtaills_delete,
			      data: this.$qs.stringify(
			        {
			          ids: this.multipleSelection.map((i) => i.id),
			        },
			        {
			          arrayFormat: "repeat",
			        }
			      ),
			    },
			    {
			      that: this,
			      methodName: "showList",
			    }
			  );
		},
		btnReloadRJ(){
			this.showList();
		},
    //确认班别 click
    onConfirmClass() {
      selectCheck(this.multipleSelection, "确认班别", false, () => {
        this.$set(this.dynamicValidateForm,'fudaoclasstypename',this.multipleSelection[0].name)
        this.$set(this.dynamicValidateForm,'fudaotypeval',this.multipleSelection[0].fudaotypeval)
        this.dynamicValidateForm.fudaoclasstypeid = this.multipleSelection[0].id;
        this.showClassModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //审批 click
    btnApprove() {
      selectCheck(this.multipleSelection, "负责人审批", true, () => {
        this.dynamicValidateForm = {};
        this.approvecheck();
      });
    },
    //提请审批 click
    btnRequestApprove() {
      selectCheck(this.multipleSelection, "提请审批", true, () => {
        confirmCallBack({
          title: "提示",
          content: "确定提请审批吗？",
          success: () => {
            this.reqApproval(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //提请审批 api
    reqApproval(ids) {
      myRequest(
        {
          method: "post",
          url: stufudaofeestandard_requestapprove,
          data: this.$qs.stringify(
            {
              ids: ids,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //审批 click
    btnApprove() {
      selectCheck(this.multipleSelection, "负责人审批", true, () => {
        this.dynamicValidateForm = {};
        this.approvecheck();
      });
    },
    //检测是否可审批 api
    approvecheck() {
      myRequest(
        {
          method: "post",
          url: techfeestandard_approvecheck,
          data: this.$qs.stringify(
            {
              ids: this.multipleSelection.map((i) => i.id),
              leadertype: "03",
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {

          that: this,
          showModal: "showApprovalModal",
        }
      );
    },
    //保存审批 api
    headApproval(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: stufudaofeestandard_approvesave,
              data: this.$qs.stringify(
                {
                  ids: this.multipleSelection.map((i) => i.id),
                  passflag: this.dynamicValidateForm.passflag,
                  approvedesc: this.dynamicValidateForm.approvedesc,
                  leadertype: "03",
                },
                {
                  arrayFormat: "repeat",
                }
              ),
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showApprovalModal",
            }
          );
        }
      });
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: stufudaofeestandard_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
					this.dialogTitle = "修改：";
          this.dynamicValidateForm = res.data.data.tstufudaofeestandard;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);

          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
			const moreDataKey = ["moreItems1"];
			const moreItemKey = [
			  "zbmanagementfee",
			  "type",
			  "zsycommission",
			  "jwcommission",
			  "cwcommission",
			  "xwzsycommission",
			  "registrationfee",
			  "jhcommission",
			  "dlproportion",
			  "jhproportion",
				"coursefee",
				"teachersfee",
				"questionfee",
				"techtype",
				"stufudaofeestandardid"
			];
			moreItemKey.map((i) => (this.dynamicValidateForm[i] = []));
			for (let moreKey in moreDataKey){
			  for(let j in moreItemKey){
			    for(let i in this.dynamicValidateForm[moreDataKey[moreKey]]){
			      for (let key in this.dynamicValidateForm[moreDataKey[moreKey]][i]){
			        if (this.dynamicValidateForm[moreDataKey[moreKey]][i][key] && key === moreItemKey[j]) {
			          //有值才push，否则是空字符串
			          this.dynamicValidateForm[key].push(this.dynamicValidateForm[moreDataKey[moreKey]][i][key]);
			        }
			      }
			    }
			  }
			}
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
							method: "post",
              url: stufudaofeestandard_save2,
              data: this.$qs.stringify(this.dynamicValidateForm, {
                arrayFormat: "repeat",
              }),
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: stufudaofeestandard_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },
    //选项表格 api（专业）
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style  lang="scss">
	.form-item-w-18 {
		.flex-free{
			.form-item-w-13{
				width: 13%;
			}

		}
	}

	@media only screen and (max-width: 1536px) {
	  .form-item-w-18 {
	  	.flex-free{
	  		.form-item-w-13{
	  			width: 16%;
	  		}

	  	}
	  }

		.form-item-w-14 .el-form-item {
		    width: 16%;
		}
	}

</style>
